import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Cadastro } from '../../classes/cadastro';
import { environment } from 'src/environments/environment';
import * as $ from 'jquery';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { identifierModuleUrl } from '@angular/compiler';
import { AuthService } from 'src/app/services/auth.service';
import { Login } from 'src/app/classes/login';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RegulamentomodalComponent } from 'src/app/includes/regulamentomodal/regulamentomodal.component';
import { ModalController } from '@ionic/angular';


@Component({
    selector: 'cadastro-component',
    templateUrl: './cadastro.component.html',
    styleUrls: ['./cadastro.component.scss']
})


export class CadastroComponent implements OnInit {

    public step: number = 1;

    public pessoa: string = 'fisica';
    public cadastro: Cadastro;
    public invalid: boolean = false;
    public msg_invalid: String;
    public user: Login;

    public validacao: Boolean = false;
    public erro_formato_email: Boolean = false;

    public conteudo: string = '';

    public userId: string = '';

    constructor(private http: HttpClient, private router: Router, private rota: ActivatedRoute, private loader: NgxUiLoaderService, private auth: AuthService, private modalService: NgbModal, private modalCtrl: ModalController ) { }
    
    ngOnInit() {

        $('.nav-section-main-mobile').removeClass("active-menu");
        this.cadastro = new Cadastro;

        const documento_confirmar = this.rota.snapshot.paramMap.get('documento');
        const token_confirmar = this.rota.snapshot.paramMap.get('token');
        //const estrangeiro = this.rota.snapshot.paramMap.get('estrangeiro');
        const precad = localStorage.getItem('precad');
        if(precad){
            let social_user = JSON.parse(precad);
            console.log(social_user);

            this.cadastro.email     = social_user.email;
            //this.cadastro.email_c   = social_user.email;
            this.cadastro.nome      = social_user.firstName+' '+social_user.lastName;
            this.cadastro.img_url   = social_user.photoUrl;
        }
        //console.log(estrangeiro);

        if (documento_confirmar && !token_confirmar) {
            this.step = 4;
        }

        if (documento_confirmar && token_confirmar) {
            this.validar_ativacao(documento_confirmar, token_confirmar);
        }

        let documento = localStorage.getItem('documento');

        /* 
            if (estrangeiro && documento){
                this.cadastro.cpf = documento;
                this.pessoa = 'estrangeiro';
            }
            
            else if (documento) {
                this.cadastro.cpf = documento.length == 11 ? documento : '';
                this.cadastro.cnpj = documento.length > 11 ? documento : '';
            } 

            if (this.cadastro.cnpj) {
                this.pessoa = 'juridica';
            }
        */
        this.cadastro.cpf = documento;

        this.loader.start();
        setTimeout(() => {
            this.loader.stop();
        }, 5000);

        /*  if(!this.cadastro.cpf)
            this.router.navigate(['index']); 
        */
        this.loader.stop();
    }

    cadastrar() {
        this.invalid = false;
        this.validacao = true;
        this.erro_formato_email = false;
        this.loader.start();

        /* if (this.pessoa == 'estrangeiro'){
            this.cadastro.estrangeiro = 1;
        } */

        console.log("01");
        if (!this.cadastro.nome) {
            this.msg_invalid = "Verifique o preenchimento, o campo NOME é obrigatorio.";
            this.invalid = true;
            this.loader.stop();
            return;
        }

        else if (!this.cadastro.cpf) {
            this.msg_invalid = "Verifique o preenchimento, o campo CPF é obrigatorio.";
            this.invalid = true;
            this.loader.stop();
            return;
        }

        else if (!this.cadastro.email) {
            this.msg_invalid = "Verifique o preenchimento, o campo EMAIL é obrigatorio.";
            this.invalid = true; 
            this.loader.stop();
            return;
        }

        else if (this.cadastro.email.indexOf('@') < 0 || this.cadastro.email.indexOf('.') < 0 || this.cadastro.email.length < 6) {
            this.msg_invalid = "Verifique o formato do EMAIL utilizado.";
            this.erro_formato_email = true;
            this.invalid = true;
            this.loader.stop();
            return;
        }

        else if (!this.cadastro.celular) {
            this.msg_invalid = "Verifique o preenchimento, o campo CELULAR é obrigatorio.";
            this.invalid = true;
            this.loader.stop();
            return;
        } 
        console.log("01");

        if (this.cadastro.nome.length < 4) {
            this.invalid = true;
            this.msg_invalid = "O nome informado parece inválido";
            this.loader.stop();
            return;
        }

        else if (this.cadastro.email.length < 4) {
            this.invalid = true;
            this.msg_invalid = "O e-mail informado parece inválido";
            this.loader.stop();
            return;
        }

        else if (this.cadastro.celular.length < 11) {
            this.msg_invalid = "Verifique o número do celular";
            this.invalid = true;
            this.loader.stop();
            return;
        } 

        else if (!this.cadastro.sexo){
            this.msg_invalid = "Selecione seu genero";
            this.invalid = true;
            this.loader.stop();
            return;
        }

        else if (!this.cadastro.aceito_term){
            alert('Você precisa aceitar os termos do regulamento para prosseguir com o cadastro');
            this.loader.stop();
            return;
        }

        console.log("02");

        this.http.post<any>(environment.API_URL.concat("cadastro/obter_email/"), {
            email: this.cadastro.email
        }).subscribe((data: any) => {
            console.log("03");

            if (data.status == 1) {
                alert("E-mail já cadastrado no sistema");
                this.invalid = true;
                this.loader.stop();
                return;
            }

            else{
               
                let validacao = this.validarCPF(this.cadastro.cpf); 
                console.log(validacao);
                if ( !validacao ) {
                    this.msg_invalid = "Verifique o número do CPF";
                    this.invalid = true;
                    this.loader.stop();
                    return;
                }
                console.log("05");
                this.http.get<any>(environment.API_URL.concat('cadastro/obter_cpf/'+this.cadastro.cpf)).subscribe( data => {
                    if(data.status !== 0){
                        alert('CPF já cadastrado');
                        this.invalid = true;
                        this.loader.stop();  
                        return;
                    }
                });
                console.log("06");
        
                this.http.post<any>(environment.API_URL.concat('cadastro/cadastrar'), this.cadastro).subscribe( data => {
                    if(data.status == 1){
                        console.log("07");
                        localStorage.setItem('id_confirmacao', data.id);
                        this.router.navigate(['/confirmacao']);
                    }
                    else{
                        alert("ops! houve um erro ao processar essa requisição, tente novamente mais tarde");
                        console.log("08");
                    }
                });

            }

        });

        
    }


    validarCPF(strCPF) {
        if (this.cadastro.cpf.length < 11) {
            return false;
        }

        var Soma;
        var Resto;
        Soma = 0;

        if (strCPF == "00000000000") {
            return false;
        }

        for (let i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
        Resto = (Soma * 10) % 11;

        if ((Resto == 10) || (Resto == 11)) Resto = 0;
        if (Resto != parseInt(strCPF.substring(9, 10))) {
            return false;
        }

        Soma = 0;
        for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
        Resto = (Soma * 10) % 11;

        if ((Resto == 10) || (Resto == 11)) Resto = 0;
        if (Resto != parseInt(strCPF.substring(10, 11))) {
            return false;
        }

        return true;

    }

    regulamento(modalRegulamento) {
        this.http.get(environment.API_URL.concat('regulamento')).subscribe((data: any) => {
            this.conteudo = data.content;
            this.modalService.open(modalRegulamento, { scrollable: true, size: 'lg' });
        });
    }

    validar_ativacao(documento, token) {
        this.http.post<any>(environment.API_URL.concat('cadastro/buscar_ativacao'), {
            token: token,
            documento: documento
        }).subscribe(data => {

            if (data.status == 0) {
                alert(data.erro);
                this.loader.stop();
                return;
            }

            this.cadastro = data.user;
            this.cadastro.token_cadastro = token;

        }, error => {
            console.log(error)
            this.loader.stop();
        });
    }

    async modal_regulamento() {
        console.log('showModal()');
        const modal = await this.modalCtrl.create({
          component: RegulamentomodalComponent
        });
    
        await modal.present();
      }


}