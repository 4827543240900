import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { CadastroComponent } from './paginas/cadastro/cadastro.component';
import { HomeComponent } from './paginas/home/home.component';
import { IndexComponent } from './paginas/index/index.component';
import { LoginComponent } from './paginas/login/login.component';
import { PropagandaComponent } from './paginas/propaganda/propaganda.component';
import { ExtratoComponent } from './paginas/extrato/extrato.component';
import { CausasComponent } from './paginas/causas/causas.component';
import { GanharEcoinsComponent } from './paginas/ganhar-ecoins/ganhar-ecoins.component';
import { PreferenciasComponent } from './paginas/preferencias/preferencias.component';
import { IndiqueComponent } from './paginas/indique/indique.component';
import { PrimeiroAcessoComponent } from './paginas/primeiro-acesso/primeiro-acesso.component';
import { PerfilUsuarioComponent } from './paginas/perfil-usuario/perfil-usuario.component';
import { CreditosCarbonoComponent } from './paginas/creditos-carbono/creditos-carbono.component';
import { ConfirmacaoComponent } from './paginas/confirmacao/confirmacao.component';
import { DescadastrarComponent } from './paginas/descadastrar/descadastrar.component';
import { FaqComponent } from './paginas/faq/faq.component';
import { EscolherPremioComponent } from './paginas/escolher-premio/escolher-premio.component';
import { DashboardComponent } from './paginas/dashboard/dashboard.component';
import { ResgatesComponent} from './paginas/resgates/resgates.component'
import { NumerosSorteComponent } from './paginas/numeros-sorte/numeros-sorte.component';
import { LeilaoComponent } from './paginas/leilao/leilao.component';
import { ClusterComponent } from './paginas/cluster/cluster.component';
import { DescontosComponent } from './paginas/descontos/descontos.component';
import { SuporteComponent } from './paginas/suporte/suporte.component';
import { BlogComponent } from './paginas/blog/blog.component';

const routes: Routes = [
  {    path: 'folder/:id',
      loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule)
  },
  {    path: '',                  component: LoginComponent,    pathMatch: 'full'  },
  {    path: 'home',              component: HomeComponent  },
  {    path: 'login/:token',      component: LoginComponent  },
  {    path: 'cadastro',          component: CadastroComponent  },
  {    path: 'propaganda',        component: PropagandaComponent  },
  {    path: 'extrato',           component: ExtratoComponent  },
  {    path: 'causas',            component: CausasComponent},
  {    path: 'ganhar-ecoins',     component: GanharEcoinsComponent},
  {    path: 'primeiro-acesso',   component: PrimeiroAcessoComponent},
  {    path: 'escolher-premio',   component: EscolherPremioComponent},
  {    path: 'indique',           component: IndiqueComponent},
  {    path: 'preferencias',      component: PreferenciasComponent},
  {    path: 'perfil-usuario',    component: PerfilUsuarioComponent},
  {    path: 'creditos',          component: CreditosCarbonoComponent},
  {    path: 'confirmacao',       component: ConfirmacaoComponent},
  {    path: 'faq',               component: FaqComponent},
  {    path: 'descadastrar',      component: DescadastrarComponent},
  {    path: 'dashboard',         component: DashboardComponent},
  {    path: 'resgates',          component: ResgatesComponent},
  {    path: 'numeros-sorte',     component: NumerosSorteComponent},
  {    path: 'leilao',            component: LeilaoComponent},
  {    path: 'descontos',         component: DescontosComponent},
  {    path: 'cluster',           component: ClusterComponent},
  {    path: 'numeros-sorte',     component: NumerosSorteComponent},
  {    path: 'suporte',           component: SuporteComponent},
  {    path: 'blog',              component: BlogComponent}
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { 
      preloadingStrategy: PreloadAllModules, 
      onSameUrlNavigation: 'reload' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
