import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CadastroComponent } from './paginas/cadastro/cadastro.component';
import { LoginComponent } from './paginas/login/login.component';
import { IndexComponent } from './paginas/index/index.component';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxViacepModule } from '@brunoc/ngx-viacep';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HeaderComponent } from './includes/header/header.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthServiceConfig, SocialLoginModule } from 'angularx-social-login';
import { NgxMaskModule } from 'ngx-mask';
import { PopupVideoComponent } from './includes/popup-video/popup-video.component';
import { HomeComponent } from './paginas/home/home.component';
import { NgxSimpleCountdownModule } from 'ngx-simple-countdown';
import { PropagandaComponent } from './paginas/propaganda/propaganda.component';
import { PerfilComponent } from './includes/perfil/perfil.component';
import { FooterComponent } from './includes/footer/footer.component';
import { FooterFixComponent } from './includes/footer-fix/footer-fix.component';
import { ExtratoComponent } from './paginas/extrato/extrato.component';
import { CausasComponent } from './paginas/causas/causas.component';
import { DoarComponent } from './includes/doar/doar.component';
import { GanharEcoinsComponent } from './paginas/ganhar-ecoins/ganhar-ecoins.component';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { PreferenciasComponent } from './paginas/preferencias/preferencias.component';
import { IndiqueComponent } from './paginas/indique/indique.component';
import { PrimeiroAcessoComponent } from './paginas/primeiro-acesso/primeiro-acesso.component';
import { PerfilUsuarioComponent } from './paginas/perfil-usuario/perfil-usuario.component';
import { CreditosCarbonoComponent } from './paginas/creditos-carbono/creditos-carbono.component';
import { PlacarComponent } from './includes/placar/placar.component';
import { ConfirmacaoComponent } from './paginas/confirmacao/confirmacao.component';
import { RegulamentomodalComponent } from './includes/regulamentomodal/regulamentomodal.component';
import { DescadastrarComponent } from './paginas/descadastrar/descadastrar.component';
import { FaqComponent } from './paginas/faq/faq.component';
import { EscolherPremioComponent } from './paginas/escolher-premio/escolher-premio.component';
import { DashboardComponent } from './paginas/dashboard/dashboard.component';
import { ResgatesComponent } from './paginas/resgates/resgates.component';
import { ObjetivosComponent } from './includes/objetivos/objetivos.component';
import { NumerosSorteComponent } from './paginas/numeros-sorte/numeros-sorte.component';
import { LeilaoComponent } from './paginas/leilao/leilao.component';
import { ContagemRegressivaComponent } from './includes/contagem_regressiva/contagem_regressiva.component';
import { CountdownModule } from 'ngx-countdown';
import { ClusterComponent } from './paginas/cluster/cluster.component';
import { DescontosComponent } from './paginas/descontos/descontos.component';
import { SuporteComponent}    from './paginas/suporte/suporte.component'
import { BlogComponent } from './paginas/blog/blog.component';
import { FinalComponent } from './includes/final/final.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    PerfilComponent,
    HeaderComponent,
    IndexComponent,
    CadastroComponent,
    LoginComponent,
    PopupVideoComponent,
    PropagandaComponent,
    FooterComponent,
    FooterFixComponent,
    ExtratoComponent,
    CausasComponent,
    DoarComponent,
    GanharEcoinsComponent,
    PreferenciasComponent,
    IndiqueComponent,
    PrimeiroAcessoComponent,
    EscolherPremioComponent,
    PerfilUsuarioComponent,
    CreditosCarbonoComponent,
    PlacarComponent,
    ConfirmacaoComponent,
    RegulamentomodalComponent,
    DescadastrarComponent,
    FaqComponent,
    DashboardComponent,
    ObjetivosComponent,
    NumerosSorteComponent,
    LeilaoComponent,
    ContagemRegressivaComponent,
    ResgatesComponent,
    ClusterComponent,
    DescontosComponent,
    NumerosSorteComponent,
    SuporteComponent,
    BlogComponent,
    FinalComponent
  ],
  entryComponents: [],
  imports: [
    CommonModule,
    FormsModule,
    BrowserModule, 
    IonicModule.forRoot(), 
    AppRoutingModule, 
    HttpClientModule,
    NgbModule,
    NgxViacepModule,
    NgxUiLoaderModule,
    FontAwesomeModule,
    SlickCarouselModule,
    SocialLoginModule,
    NgxMaskModule.forRoot(),
    NgxSimpleCountdownModule,
    CurrencyMaskModule,
    NgxUiLoaderModule,
    CountdownModule   
    
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: 'SocialAuthServiceConfig',
      useValue:{
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider('793225731827-93d7356f4jsk37ig9rldtdet8tf2jsfi.apps.googleusercontent.com')
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('3146593332286340')
          }
        ]
      } as SocialAuthServiceConfig
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
