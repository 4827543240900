import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Renderer2, ViewChild, EventEmitter, Output, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ignoreElements } from 'rxjs/operators';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { environment } from 'src/environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-leilao',
  templateUrl: './leilao.component.html',
  styleUrls: ['./leilao.component.scss'],
})

export class LeilaoComponent implements OnInit {

  enquete: any;
  pergunta: any;
  resposta: any;
  etapa: number = 0;
  porcentagens: any = [];
  ganhou_moedas: any = 0;
  
  propaganda:boolean = false;
  objetivos:boolean = false;
  modal_final = false;
  
  user_id;
  user_name;

  moedas = 3.5;
  modulos_concluidos = 2;
  modulos_total = 5;

  public leilao: any;
  public lances: any;
  public maior_lance: any;
  public meu_lance: any;
  public participacoes: any;

  public id_leilao: any;
  public proximo_lance: number;
  public data_hoje:Date = new Date();
  public interval_leilao:any;

  //@Output() timer_video = new EventEmitter();

  public slideOpts = {
    slidesPerView: 1,
    speed: 300
  }

  media_moedas;
  concluido = false;
  data_lance;

  porcentagem = 0;

  modulos = [
    { 'id': 1, 'campo' : 'modulo_enquete',      'nome': 'Modulo Enquete',      'moedas': 10, 'status':0 },
    { 'id': 2, 'campo' : 'modulo_sorteio',      'nome': 'Modulo Sorteio',      'moedas': 10, 'status':0 },
    { 'id': 3, 'campo' : 'modulo_leilao',       'nome': 'Modulo Leilao',       'moedas': 10, 'status':0 },
    { 'id': 4, 'campo' : 'modulo_ecoplay',      'nome': 'Modulo Ecomode',      'moedas': 10, 'status':0 },
    { 'id': 5, 'campo' : 'modulo_atendimento',  'nome': 'Modulo Atendimento',  'moedas': 10, 'status':0 },
    { 'id': 6, 'campo' : 'modulo_resgate',      'nome': 'Modulo Resgate',      'moedas': 10, 'status':0 }
  ]

  public foto_src = "https://cdn-icons-png.flaticon.com/512/149/149071.png"; 
  
  @ViewChild('modalAcao', { static: false }) private modalAcao: TemplateRef<any>;

  constructor( renderer: Renderer2, private route: ActivatedRoute, private router: Router, private modalService: NgbModal, private sanitizer: DomSanitizer, private http: HttpClient, private loader: NgxUiLoaderService) { 

  }

  ngOnInit() {
    //localStorage.removeItem('userId');
    let foto_src = localStorage.getItem('foto_src');
    
    if(foto_src){
      this.foto_src = foto_src;
    }

    this.user_id = localStorage.getItem('userId');
    this.user_name = localStorage.getItem('userName');
    this.data_lance = localStorage.getItem('data_lance');
    this.meu_lance = localStorage.getItem('meu_lance') ?? 0;
    
    if(!this.user_id){
      this.router.navigate(['/login']);
    }

    
    this.obter_dados();
  }

  obter_dados(){

    this.http.get<any>(environment.API_URL.concat('pontos?id_participante='+this.user_id)).subscribe(data => {
      
      this.moedas = data.moedas;
      this.modulos_total = data.modulos_total;
      this.modulos_concluidos = data.modulos_concluidos;
      this.modulos = data.modulos;

      console.log(this.modulos);
    
      this.obter_status();
      this.calc_porcentagem();
    });

  }

  fazer_lance() {

    if(this.moedas < 80){
      this.abrir_objetivos();
      return;
    }

    this.data_lance = Date.now();
    localStorage.setItem('data_lance', this.data_lance);

    this.abrir_final();
    return;
  }

  obter_status(){ 
   
    this.http.get<any>(environment.API_URL.concat('participantes/obter_avaliacao/'+this.user_id)).subscribe( data => {
      if(data.status == 1){
        this.concluido = true;
        localStorage.setItem('meu_lance', this.moedas.toString());
        this.meu_lance = this.moedas;
      }
    });
  
  }

  concluir(){
    this.modal_final = false;
    this.concluido = true;
    localStorage.setItem('meu_lance', this.moedas.toString());
    this.meu_lance = this.moedas;
  }

  abrir_objetivos(){
    this.objetivos = true;
  }

  abrir_final(){
    const audioWin = new Audio('assets/audio/audio_final.mpga');
    audioWin.play();
    this.modal_final = true;
  }

  reload_component(){
    setTimeout(() => {
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigate(['./'], {
        relativeTo: this.route
      })
    }, 1000);
  }

  calc_porcentagem(){
    this.porcentagem = (this.modulos_concluidos / this.modulos_total) * 100
    this.media_moedas = 100 / this.modulos_total; 
  }

}


