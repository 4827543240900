import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import * as $ from 'jquery';
import { RegulamentomodalComponent } from '../regulamentomodal/regulamentomodal.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})

export class FooterComponent implements OnInit {

  constructor(private modalCtrl: ModalController) { }

  async showModal() {
    console.log('showModal()');
    const modal = await this.modalCtrl.create({
      component: RegulamentomodalComponent
    });

    await modal.present();
  }

  ngOnInit() {
  }

}
