import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Output, EventEmitter, Renderer2, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as $ from 'jquery';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-ganhar-ecoins',
  templateUrl: './ganhar-ecoins.component.html',
  styleUrls: ['./ganhar-ecoins.component.scss'],
})
export class GanharEcoinsComponent implements OnInit {

  public propaganda:boolean = false;
  
  user_id;
  user_name;
  
  ecoins;
  causas_apoiadas;
  ecoins_doados;
  porcentagem_doar;
 
  @Output() timer_video = new EventEmitter();

  public modal_ganhou:boolean = false;
  count_navigate;
  
  @ViewChild('target') target : HTMLElement; 

  public slideOpts = {
    slidesPerView: 1,
    speed: 300
  }

  public ganhar_moedas:boolean = false;
  constructor( renderer: Renderer2, private route: ActivatedRoute, private router: Router, private http:HttpClient ) { }

  ngOnInit() {

    this.user_id = localStorage.getItem('userId');
    this.user_name = localStorage.getItem('userName');
    
    
    if(!this.user_id){
      this.router.navigate(['/login']);
    }
    
    this.obter_dados();
    //this.counter_bonus();
  }

  
  obter_dados(){

    this.http.get<any>(environment.API_URL.concat('pontos?id_participante='+this.user_id)).subscribe(data => {
      console.log(data);
      this.ecoins = data.ecoins;
      this.ecoins_doados = data.ecoins_doados;
      this.causas_apoiadas = data.causas_apoiadas;

      this.calc_porc_doar();

    });
    
  }

 

  assistir_video(){
    this.propaganda = true;
  }


  update_ecoins(event){  

    this.modal_ganhou = true;
    let novo_saldo = this.ecoins + event.ganhou;
    let descricao = 'Assistiu Vídeo';

    this.http.post<any>(environment.API_URL.concat('pontos/creditar_ecoins'), {
      id_participante: this.user_id, ecoins: event.ganhou, descricao: descricao
    }).subscribe( data => {
      if(data.status == 1){

        this.propaganda = false;
        this.animacao_saldo(novo_saldo);
        this.calc_porc_doar();
      }
      else{
        console.log("Erro");
      }
    });
    

    this.propaganda = false;
    setTimeout(()=>{window.scrollTo(0,0);}, 200)
  }

  scroll(el: HTMLElement){
    el.scrollIntoView();
  }

  counter_bonus(){
    let count_navigate = parseInt(localStorage.getItem('count_navigate')) + 1;
    localStorage.setItem('count_navigate', String(count_navigate));
    this.count_navigate = count_navigate;

    if(this.count_navigate >= 3){
      this.http.post<any>(environment.API_URL.concat('pontos/verificar_bonus'),
      {id_participante : this.user_id}).subscribe( data => {
        if(data.status == 1){

          setTimeout(()=>{
            this.modal_ganhou = true;
            this.animacao_saldo(this.ecoins + data.ecoins_ganhos);
            this.calc_porc_doar();
            localStorage.setItem('count_navigate', '0');
          }, 3000);
        }
      });
    }
  }

  animacao_saldo(novo_saldo){

    let animacao = setInterval(()=>{
      if( this.ecoins < novo_saldo ){
        this.ecoins += 0.01;
      }
      else{
        this.ecoins = novo_saldo;
        localStorage.setItem('ecoins', novo_saldo);
        clearInterval(animacao);

        setTimeout(()=>{
          this.modal_ganhou = false;
        },2000);
      
      }

    }, 50)
  }

  
  calc_porc_doar(){
    this.porcentagem_doar = (this.ecoins / 5) * 100
  }

}
