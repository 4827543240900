import { Component, OnInit } from '@angular/core';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { User } from '../../classes/user';
import { ActivatedRoute, Router } from '@angular/router';

import * as $ from 'jquery';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-confirmacao',
  templateUrl: './confirmacao.component.html',
  styleUrls: ['./confirmacao.component.scss'],
})
export class ConfirmacaoComponent implements OnInit {

  public step: number = 1;
  public id_confirmacao;

  public invalid: boolean = false;
  public msg_invalid: string = null;
  public participante: User;
  codigo1=''; codigo2=''; codigo3=''; codigo4='';
  ultimos_numeros;

  constructor(private rota: ActivatedRoute, private loader: NgxUiLoaderService, private http: HttpClient, private router:Router) { }

  ngOnInit() {
    this.participante = new User();
    this.id_confirmacao = localStorage.getItem('id_confirmacao');
    this.obter_dados();
  }

  obter_dados(){

    this.http.get<any>(environment.API_URL.concat('cadastro/obter_id/'+this.id_confirmacao)).subscribe( data => {

      if(data.status == 1){
        this.participante = data.user;
        this.ultimos_numeros = data.user.celular.substr(7, 4);
        if(this.participante.confirmou_cadastro == 'S'){
          this.step1();
        }
        this.codigo1 = ''; this.codigo2 = ''; this.codigo3 = ''; this.codigo4 = '';
      }
      else{
        console.log(data);
      }

    });
  }

  step1(){

    if(this.participante.celular.length < 11){
      alert('Verifique o número do celular') 
      return;
    }

    this.http.post<any>(environment.API_URL.concat('cadastro/solicitar_token'), {documento: this.participante.cpf}).subscribe(data =>{
      if(data.status == 1){
        this.step = 2;
        setTimeout(()=>{
          $("#codigo1").select();
        }, 1000);
      }
      else{
        alert('Houve um erro ao processar sua requisição, tente novamente mais tarde');
      }
    });

  }

  next_input(event){
    let elem = event.target.nextElementSibling;
    elem.select();
  }

  gerar_token() {
      this.http.post<any>(environment.API_URL.concat('cadastro/solicitar_token'), {
          documento: this.participante.cpf
      }).subscribe(data => {

          if (data.status == 0) {
              alert(data.erro);
              this.loader.stop();
              return;
          }
          alert('Novo token enviado por e-mail. Não esqueça de checar sua Caixa de SPAM.');
          this.loader.stop();

      }, error => {
          console.log(error)
          this.loader.stop();
      });
  }
  
  confirmar_cadastro() {

    this.loader.start();

    let token = '';
    this.participante.token_cadastro = token + this.codigo1 + this.codigo2 + this.codigo3 + this.codigo4;

    console.log(this.participante.token_cadastro);
    if (!this.participante.token_cadastro || this.participante.token_cadastro == '') {
        this.msg_invalid = "Informe o código enviado por e-mail";
        this.invalid = true;
        this.loader.stop();
        return;
    }

    this.http.post<any>(environment.API_URL.concat('cadastro/confirmar_cadastro'), {
        token: this.participante.token_cadastro,
        documento: this.participante.cpf
    }).subscribe(data => {

      if (data.status == 0) {
          alert(data.erro);
          this.loader.stop();
          return;
      }
      //Ação para login
      localStorage.setItem('isLogged', 'true');
      localStorage.setItem('userName', data.nome);
      localStorage.setItem('userId', data.id);
      this.router.navigate(['/primeiro-acesso']);

    }, error => {
        console.log(error)
        this.loader.stop();
    });
  }

}
