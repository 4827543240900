import { HttpClient } from '@angular/common/http';
import { Component, Injectable, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { NgForm , FormGroup, FormControl, Validators} from '@angular/forms';
import { Location } from '@angular/common';

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.scss'],
})@Injectable()

export class PerfilComponent implements OnInit, OnChanges {
  user_id;
  user_name;

  public porcentagem;
  @Input() public pagina_perfil:boolean = false;
  @Input() public moedas:number;
  @Input() public modulos:number;
  @Input() public modulos_concluidos:number;
  @Input() public modulos_total:number;
  public objetivos;

  public foto_src = "https://cdn-icons-png.flaticon.com/512/149/149071.png"; 



  public imageSrc = '/assets/images/imagem-perfil.png';
  myForm = new FormGroup({
          file: new FormControl('', [Validators.required]),
          fileSource: new FormControl('', [Validators.required])
      });

  constructor(private router: Router, private http: HttpClient, private location:Location) {  }

  ngOnInit() {

    let foto_src = localStorage.getItem('foto_src');
    if(foto_src){
      this.foto_src = foto_src;
    }

    this.user_id = localStorage.getItem('userId');
    this.user_name = localStorage.getItem('userName');
    this.calc_porcentagem();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.calc_porcentagem();    
  }

  calc_porcentagem(){
    this.porcentagem = (this.modulos_concluidos / this.modulos_total) * 100
  }


  abrir_objetivos(){
    this.objetivos = true;
  }

  upload_image(event){
    const reader = new FileReader();

    if(event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {

        this.imageSrc = reader.result as string;
        this.myForm.patchValue({
          fileSource: reader.result
        });
        this.submit();
      };
   
    }

  }

  submit(){
    console.log(this.myForm.value);
    this.http.post(environment.API_URL.concat('participantes/upload_foto/'+this.user_id), this.myForm.value)
    .subscribe(
        (data:any) => {

            if(data.status == 1){
              this.foto_src = data.src;
              localStorage.setItem('foto_src', data.src);
                console.log(data);
            }else{
                console.log(data);
            }

        }
    );
  }

  goback(){
    this.location.back();
  }

}
