import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { environment } from 'src/environments/environment';
import * as $ from 'jquery';
import { DomSanitizer } from '@angular/platform-browser';
import { ModalController } from '@ionic/angular';
//import { pdfDefaultOptions } from 'ngx-extended-pdf-viewer';

@Component({
  selector: 'app-resgates',
  templateUrl: './resgates.component.html',
  styleUrls: ['./resgates.component.scss']
})

export class ResgatesComponent implements OnInit {

  public imageSrc = '/assets/images/imagem-perfil.png';
  public user_name: string = '';

  public enquete;

  public user_id: any;

  public isLogged:boolean;

  public audioAcertou: any = 'assets/audio/audioCoin.mpeg';
  public audioErrou: any;

  modal_ganhou;

  moedas;
  media_moedas;
  moedas_ganhos;
  modulos_total;
  modulos_concluidos;
  modulos;
  porcentagem;

  concluido;
  objetivos:boolean = false;

  constructor(private http: HttpClient, private route: ActivatedRoute, private modalController:ModalController, private router: Router, private loader: NgxUiLoaderService, private sanitizer: DomSanitizer) {
    //pdfDefaultOptions.assetsFolder = 'bleeding-edge';
  }

  ngOnInit(): void {
    


    $('.nav-section-main-mobile').removeClass("active-menu");
    if(localStorage.getItem('userId')){
      this.isLogged = true;
      this.user_id = parseInt(localStorage.getItem('userId'));
      let nome_usuario = localStorage.getItem('userName');
      this.user_name = nome_usuario;

      this.obter_dados();
    }
    else{
      this.isLogged = false;
    }

  }

  obter_dados(){

    this.http.get<any>(environment.API_URL.concat('pontos?id_participante='+this.user_id)).subscribe(data => {
      
      this.moedas = data.moedas;
      this.modulos_total = data.modulos_total;
      this.modulos_concluidos = data.modulos_concluidos;
      this.modulos = data.modulos;

      console.log(this.modulos);

      if( data.modulos[3].status == 1 ){
        this.concluido = 1
      }
    

      this.calc_porcentagem();
    });

  }

  calc_porcentagem(){
    this.porcentagem = (this.modulos_concluidos / this.modulos_total) * 100
    this.media_moedas = 100 / this.modulos_total;
    this.moedas_ganhos = this.media_moedas;
  }

  responder_enquete(id_relacionamento) {
    
    this.http.post<any>(environment.API_URL.concat('pontos/creditar_moedas'), {
      id_participante: this.user_id,
      id_relacionamento: id_relacionamento,
      descricao: this.enquete,
      moedas: this.media_moedas
    }).subscribe(data => {

      if(data.status == 1){
        const audioWin = new Audio('assets/audio/audioCoin.mpeg');
        audioWin.play();

        this.modal_ganhou = true;
        this.animacao_saldo();
        this.obter_dados();
      }
      else{
        alert(data.message);
       
      }
      this.loader.stop();
    });
  }


  animacao_saldo(){
    let novo_saldo = this.moedas + this.moedas_ganhos;
    let animacao = setInterval(()=>{ 

      if( this.moedas < novo_saldo ){
        this.moedas += 0.1;
        this.moedas_ganhos -= 0.1;
      }
      else{
        this.moedas = novo_saldo;
        this.moedas_ganhos = 0;
        clearInterval(animacao);
        this.calc_porcentagem();

        setTimeout(()=>{
          this.modal_ganhou = false;
        }, 2000)
      }

    }, 50);

  }

  counter_bonus(){
    let count_navigate = parseInt(localStorage.getItem('count_navigate')) + 1;
    localStorage.setItem('count_navigate', String(count_navigate));
  }

  calc_porc_doar(){
    this.porcentagem = (this.moedas / 5) * 100;
  }

  abrir_objetivos(){
    this.objetivos = true;
  }

}