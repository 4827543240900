import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-indique',
  templateUrl: './indique.component.html',
  styleUrls: ['./indique.component.scss'],
})
export class IndiqueComponent implements OnInit {

  extrato = [
    {'nome' : 'Amigo A', 'tipo' : 'Recebidos', 'valor': '2.00'},
    {'nome' : 'Amigo B', 'tipo' : 'Recebidos', 'valor': '2.00'},
    {'nome' : 'Amigo X', 'tipo' : 'Recebidos', 'valor': '2.00'}
  ];

  cod_indicacao = 'YEA21-20'

  constructor() { }

  ngOnInit() {}

}
