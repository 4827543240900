import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { environment } from 'src/environments/environment';
import * as $ from 'jquery';
import { DomSanitizer } from '@angular/platform-browser';
import { ModalController } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-numeros-sorte',
  templateUrl: './numeros-sorte.component.html',
  styleUrls: ['./numeros-sorte.component.scss'],
})
export class NumerosSorteComponent implements OnInit {
  
  public imageSrc = '/assets/images/imagem-perfil.png';
  public user_name: string = '';

  public enquete: any;

  public porcentagens: any = [];

  public user_id: any;

  public etapa: number = 0;

  public isLogged:boolean;

  public audioAcertou: any = 'assets/audio/audioCoin.mpeg';

  moedas; 
  moedas_ganhos;
  modal_ganhou;
  media_moedas; 
  modulos_total;
  modulos_concluidos;
  modulos;
  concluido;
  porcentagem;

   constructor(private http: HttpClient, private route: ActivatedRoute, private modalController:ModalController, private router: Router, private loader: NgxUiLoaderService, private sanitizer: DomSanitizer) {
    //pdfDefaultOptions.assetsFolder = 'bleeding-edge';
  }


  ngOnInit(): void {
    $('.nav-section-main-mobile').removeClass("active-menu");
    if(localStorage.getItem('userId')){
      this.isLogged = true;
      this.user_id = parseInt(localStorage.getItem('userId'));
      let nome_usuario = localStorage.getItem('userName');
      this.user_name = nome_usuario;

      this.obter_dados();

      console.log(this.porcentagens);
    }
    else{
      this.isLogged = false;
    }

  }

  obter_dados(){

    this.http.get<any>(environment.API_URL.concat('pontos?id_participante='+this.user_id)).subscribe(data => {
      
      this.moedas = data.moedas;
      this.modulos_total = data.modulos_total;
      this.modulos_concluidos = data.modulos_concluidos;
      this.modulos = data.modulos;

      console.log(data.modulos);

      if(data.modulos[4].status == 1){
        this.concluido = true;
      }

      console.log(this.concluido);
      this.media_moedas = 100 / this.modulos_total; 

    });

  }

  gerar_numero() { 

    let numeros = $('.numeros').children();

    console.log();
    
    let anima = setInterval(()=>{
      let random = Math.random();
      numeros.eq(0).html(String(random).substring(2,3));
      numeros.eq(1).html(String(random).substring(3,4));
      numeros.eq(2).html(String(random).substring(4,5));
      numeros.eq(3).html(String(random).substring(5,6));  
    }, 100);

    setTimeout(()=>{
      clearInterval(anima);
      this.responder_enquete(5)
    }, 2500);
  }

  responder_enquete(id_relacionamento) {
    
    this.http.post<any>(environment.API_URL.concat('pontos/creditar_moedas'), {
      id_participante: this.user_id,
      id_relacionamento: id_relacionamento,
      descricao: 'Gerou número da sorte',
      moedas: this.media_moedas
    }).subscribe(data => {

      if(data.status == 1){
        const audioWin = new Audio('assets/audio/audioCoin.mpeg');
        audioWin.play();

        this.modal_ganhou = true;
        this.animacao_saldo();
        this.obter_dados();
      }
      else{
        alert(data.message);
       
      }
      this.loader.stop();
    });
  }


  animacao_saldo(){
    let novo_saldo = this.moedas + this.moedas_ganhos;
    let animacao = setInterval(()=>{ 

      if( this.moedas < novo_saldo ){
        this.moedas += 0.1;
        this.moedas_ganhos -= 0.1;
      }
      else{
        this.moedas = novo_saldo;
        this.moedas_ganhos = 0;
        clearInterval(animacao);
        this.calc_porcentagem();

        setTimeout(()=>{
          this.modal_ganhou = false;
        }, 2000)
      }

    }, 50);

  }
  
  calc_porcentagem(){
    this.media_moedas = 100 / this.modulos_total; 
    this.porcentagem = (this.modulos_concluidos / this.modulos_total) * 100
    this.moedas_ganhos = this.media_moedas;
  }

}

