import { HttpClient } from '@angular/common/http';
import { Component, Injectable, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingController } from '@ionic/angular';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-escolher-premio',
  templateUrl: './escolher-premio.component.html',
  styleUrls: ['./escolher-premio.component.scss'],
}) @Injectable()

export class EscolherPremioComponent implements OnInit {


  id_usuario;
  user_name = localStorage.getItem('userName');
  premios:Array<any> = [
    {
      'id': 1, 
      'nome' : 'Ingresso Cinema',  
      'descricao' : 'Um ingresso de cinema da rede cinemark',
      'img' : 'assets/primeiro-acesso/p_ingresso.png',   
      'selecionado': false
    },
    {
      'id': 2, 
      'nome' : 'Vale Bombom',  
      'descricao' : 'Um vale bombom de uma rede de chocolates',  
      'img' : 'assets/primeiro-acesso/p_bombom.png',     
      'selecionado': false
    },
  ]
  premio_selecionado;

  constructor(private router: Router, private loader: LoadingController, private http: HttpClient) { }

  ngOnInit() {
    
    //this.loading();    
    this.id_usuario = localStorage.getItem('userId');
  }


  async loading(){
    let loader = await this.loader.create({
      message: '',
      duration: 1500
    });
    await loader.present();
  }


  confirmar_premio(){

    const premio_selecionado = this.premio_selecionado;

    if(!premio_selecionado){
      alert('selecione seu prêmio');
      return;
    }

    let usuario = {
      premio_selecionado: premio_selecionado,
      primeiro_acesso: 'N'
    };

    this.http.post<any>(environment.API_URL.concat('participantes/update'),
    {id: this.id_usuario, user: usuario}).subscribe( data => {
      if(data.status == 1){
        this.router.navigate(['/home']).then(()=>{location.reload()});
      }
      else{
        alert('Ocorreu um erro, tente novamente mais tarde');
      }
    })


  }


}
