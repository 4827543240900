import { Component } from '@angular/core';


import { Platform } from '@ionic/angular';
//import { SplashScreen } from '@ionic-native/splash-screen/ngx';
//import { StatusBar } from '@ionic-native/status-bar/ngx';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})

export class AppComponent {

  private regulamento;

  public appPages = [ 
    { title: 'Home',              subtitle: 'Página inicial',                 url: '/home',               icon: 'home' },
    { title: 'Clusters',          subtitle: 'Motor de pontos',                url: '/cluster',            icon: 'albums' },
    { title: 'EcoMode',           subtitle: 'Social Loyalty',                 url: '/causas',             icon: 'leaf' },
    { title: 'Resgates',          subtitle: 'E-commerce ou cartões',          url: '/resgates',           icon: 'gift' },
    { title: 'Lucky Number',      subtitle: 'Números da sorte e sorteios',    url: '/numeros-sorte',      icon: 'sparkles' },
    { title: 'Leilão',            subtitle: 'Leilão virtual',                 url: '/leilao',             icon: 'hammer' },
    { title: 'Descontos',         subtitle: 'Parceiros',                      url: '/descontos',          icon: 'pricetag' }, 
    { title: 'Dashboard',         subtitle: 'Realtime data',                  url: '/dashboard',          icon: 'analytics' },
    { title: 'Blog',              subtitle: 'Mais sobre a new mode',          url: '/blog',               icon: 'chatbox-ellipses' }
    
  ];
  constructor( private platform: Platform,
   // private splashScreen: SplashScreen,
   // private statusBar: StatusBar
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
     // this.statusBar.styleDefault();
     // this.splashScreen.hide();
    });
  } 

  abrir_regulamento(){
    this.regulamento = true;
  }
}
