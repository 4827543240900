import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { CountdownComponent, CountdownConfig } from 'ngx-countdown';

@Component({
  selector: 'contagem-regressiva',
  templateUrl: './contagem_regressiva.component.html',
  styleUrls: ['./contagem_regressiva.component.css']
})

export class ContagemRegressivaComponent implements OnInit {
  
  @ViewChild('cd', { static: false }) private countdown: CountdownComponent;

  @Input()
  data_fim: string;
  dateTo: any;
  constructor() { }

  
  ngOnInit(): void {
    this.data_fim = '2022-07-02 22:00:00';

    let data_fim = new Date(this.data_fim.replace(' ', 'T'));

    this.dateTo = data_fim.getTime() / 1000;
    //this.countdown.begin()
  }

  handleEvent(e){
    console.log(e);
  }

}
