import { Component, OnInit } from '@angular/core';
import { Router, Routes } from '@angular/router';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-propaganda',
  templateUrl: './propaganda.component.html',
  styleUrls: ['./propaganda.component.scss'],
})
export class PropagandaComponent implements OnInit {

  
  constructor( private route:Router ) { }

  public dateTo = new Date().getTime() / 1000 + 5;
  
  public modalOpen:boolean = true; 
  public liberado:boolean = false;

  ngOnInit() {
    this.resetTime();
  }

  resetTime(){
    this.dateTo = new Date().getTime() / 1000 + 5;
  }

  liberar_fechamento(){
    this.liberado = true;
  }

  receber_premio(){
    const audioWin = new Audio('assets/audio/audioCoin.mpeg');
    audioWin.play();
    this.route.navigate(['home']);
  }

}
