import { Component, Input, OnInit, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { CheckboxCustomEvent } from '@ionic/angular';

@Component({
  selector: 'app-objetivos',
  templateUrl: './objetivos.component.html',
  styleUrls: ['./objetivos.component.scss'],
})
export class ObjetivosComponent implements OnInit {

  constructor( private router : Router) { }

  canDismiss = false;
  presentingElement = null;
  @Input() isOpen;
  @Input() porcentagem = 80;
  @Input() modulos;
  @Input() media_moedas;
  @Output() fechar = new EventEmitter();


  ngOnInit() {
    this.count_complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    console.log(changes);

    if(changes.isOpen.currentValue == false){
      this.isOpen = false;
    }

    if(changes.isOpen.currentValue == true){
      for(let x = 0; x < this.modulos.length; x++){
        
        this.modulos[x].nome = this.modulos[x].nome.replace('Modulo ', '');
        this.modulos[x].nome = this.modulos[x].nome.replace('Video', 'Vídeo');
        this.modulos[x].nome = this.modulos[x].nome.replace('Ecoplay', 'EcoMode');

        switch(this.modulos[x].campo){
          case "modulo_video": 
            this.modulos[x].rota = '/home';
          break;
          case "modulo_enquete": 
            this.modulos[x].rota = '/home';
          break;
          case "modulo_ecoplay": 
            this.modulos[x].rota = '/causas';
          break;
          case "modulo_resgate": 
            this.modulos[x].rota = '/resgates';
          break;
          case "modulo_sorteio": 
            this.modulos[x].rota = '/numeros-sorte';
          break;
          case "modulo_enquete": 
            this.modulos[x].rota = '/home';
          break;
          case "modulo_suporte": 
            this.modulos[x].rota = '/suporte';
          break;
          case "modulo_cluster": 
            this.modulos[x].rota = '/cluster';
          break;
        }
        
      }
    }
  }

  onTermsChanged(event: Event) {
    const ev = event as CheckboxCustomEvent;
    this.canDismiss = ev.detail.checked;
  }

  close() {
    this.fechar.emit({ fechar: true });
  }

  close_routing(route){
    this.fechar.emit({fechar : true});

    setTimeout(()=>{
      this.router.navigate([route]);
    },500);
  }

  count_complete(){
    console.log('entrou');
    
  }
}
