import { ViewportScroller } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-causas',
  templateUrl: './causas.component.html',
  styleUrls: ['./causas.component.scss'],
})
export class CausasComponent implements OnInit {

  constructor( private scroller: ViewportScroller, private router: Router, private http:HttpClient ) { }

  user_id;
  user_name;
  
  ecoins;
  causas_apoiadas;
  ecoins_doados;
  
  id_causa;
  agradecimento = false;
  msg;

  moedas;
  moedas_ganhos;
  modulos_total;
  modulos_concluidos;
  modulos;

  propaganda;
  concluido = false;
  enquete;

  modal_ganhou;
  media_moedas;


  ngOnInit() {    
    this.user_id = localStorage.getItem('userId');
    this.user_name = localStorage.getItem('userName');
    
    if(!this.user_id){
      this.router.navigate(['/login']);
    }

    this.obter_dados();
    //this.counter_bonus();

  }


  obter_dados(){

    this.http.get<any>(environment.API_URL.concat('pontos?id_participante='+this.user_id)).subscribe(data => {
      
      this.moedas = data.moedas;
      this.modulos_total = data.modulos_total;
      this.modulos_concluidos = data.modulos_concluidos;
      this.modulos = data.modulos;

      console.log(data.modulos);

      if(data.modulos[2].status == 1){
        this.concluido = true;
      }

      console.log(this.concluido);
      this.media_moedas = 100 / this.modulos_total; 

    });

  }

  assistir_video(){
    localStorage.setItem('timer', String(new Date().getTime() / 1000 + 5));
    this.propaganda = true;
  }

  scroll(el: HTMLElement){
    el.scrollIntoView();
  }

  counter_bonus(){
    let count_navigate = parseInt(localStorage.getItem('count_navigate')) + 1;
    localStorage.setItem('count_navigate', String(count_navigate));
  }

  responder_enquete(id_relacionamento) {
    
    this.http.post<any>(environment.API_URL.concat('pontos/creditar_moedas'), {
      id_participante: this.user_id,
      id_relacionamento: id_relacionamento,
      descricao: this.enquete,
      moedas: this.media_moedas
    }).subscribe(data => {

      if(data.status == 1){
        const audioWin = new Audio('assets/audio/audioCoin.mpeg');
        audioWin.play();

        this.modal_ganhou = true;
        this.animacao_saldo();
        this.obter_dados();
      }
      else{
        alert(data.message);
       
      }
    });
  }

  animacao_saldo(){
    let novo_saldo = this.moedas + this.moedas_ganhos;
    let animacao = setInterval(()=>{ 

      if( this.moedas < novo_saldo ){
        this.moedas += 0.1;
        this.moedas_ganhos -= 0.1;
      }
      else{
        this.moedas = novo_saldo;
        this.moedas_ganhos = 0;
        clearInterval(animacao);
        this.calc_porcentagem();

        setTimeout(()=>{
          this.modal_ganhou = false;
        }, 2000)
      }

    }, 50);

  }

  calc_porcentagem(){
    this.media_moedas = 100 / this.modulos_total; 
    this.moedas_ganhos = this.media_moedas;
  }

}
