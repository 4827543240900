import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-extrato',
  templateUrl: './extrato.component.html',
  styleUrls: ['./extrato.component.scss'],
})
export class ExtratoComponent implements OnInit {

  user_id;
  user_name;
  
  ecoins;
  causas_apoiadas;
  ecoins_doados;

  extrato:any = [];

  constructor(private router:Router, private http: HttpClient) { }

  ngOnInit() {
    
    this.user_id = localStorage.getItem('userId');
    this.user_name = localStorage.getItem('userName');
    
    if(!this.user_id){
      this.router.navigate(['/login']);
    }

    this.obter_dados();
    this.counter_bonus();
    this.obter_extrato();
  }

  obter_dados(){
    
    this.http.get<any>(environment.API_URL.concat('pontos?id_participante='+this.user_id)).subscribe(data => {
      this.ecoins = data.ecoins;
      this.ecoins_doados = data.ecoins_doados;
      this.causas_apoiadas = data.causas_apoiadas;
    });
    
  }

  obter_extrato(){

    this.http.get<any>(environment.API_URL.concat('pontos/obter_extrato_moedas/'+this.user_id)).subscribe( data => {
      
      if( data.status == 1 ){
        console.log(data);
        this.extrato = data.extrato;

        for(let i =0; this.extrato.length < 0; i++){
          this.extrato[i]['data'] = new Date(this.extrato[i].data);
        }

      }
      else{
        console.log(data);
      }

    })
  }

  counter_bonus(){
    let count_navigate = parseInt(localStorage.getItem('count_navigate')) + 1;
    localStorage.setItem('count_navigate', String(count_navigate));
  }






}
