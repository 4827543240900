import { Component, Injectable, OnInit, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-popup-video',
  templateUrl: './popup-video.component.html',
  styleUrls: ['./popup-video.component.scss'],
}) @Injectable()

export class PopupVideoComponent implements OnInit {

  constructor(private modalController: ModalController, private route: ActivatedRoute, private router:Router) { }
 
  @Input() isOpen = true;
  @Input() titulo;
  @Input() video;
  @Input() gera_moedas = 0;
  @Input() media_moedas;
  @Input() timer;
  
  @Output() ganhou_moedas = new EventEmitter();
  
  public liberado:boolean = false;

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    console.log(changes);
    if(changes.isOpen.currentValue){
      this.liberado = false;
      this.iniciar_contagem();
    }

  }

  //SEM USO
  async presentModal(){
    const modal = await this.modalController.create({
      component: PopupVideoComponent,
      cssClass: 'popup-video',
      swipeToClose: false,
      presentingElement: await this.modalController.getTop(),
      backdropDismiss: false
    });

    return await modal.present();
  }

  liberar_fechamento(){
    this.liberado = true;
  }

  receber_premio(){

    if(this.gera_moedas == 1){
      const audioWin = new Audio('assets/audio/audioCoin.mpeg');
      audioWin.play();
    }
    this.ganhou_moedas.emit({ganhou : this.media_moedas});

  }

  iniciar_contagem(){
    this.timer;
    let contagem = setInterval(()=>{
      this.timer -- ;
      if(this.timer <= 0){
        this.liberar_fechamento();
        clearInterval(contagem);
      }
    }, 1000)
  }


}
