import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Renderer2, ViewChild, EventEmitter, Output, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ignoreElements } from 'rxjs/operators';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { environment } from 'src/environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { User } from 'src/app/classes/user';
@Component({
  selector: 'app-perfil-usuario',
  templateUrl: './perfil-usuario.component.html',
  styleUrls: ['./perfil-usuario.component.scss'],
})
export class PerfilUsuarioComponent implements OnInit {


  public preferencias = [
    {"value":'esportes' ,"nome":"Esportes", 'id': 1, 'checked':false},
    {"value":'cinema' ,"nome":"Cinema/Teatro", 'id': 2, 'checked':false},
    {"value":'tecnologia' ,"nome":"Tecnologia", 'id': 3, 'checked':false},
    {"value":'decoracao' ,"nome":"Decoração", 'id': 4, 'checked':false},
    {"value":'viagens' ,"nome":"Viagens", 'id': 5, 'checked':false},
    {"value":'leitura' ,"nome":"Leitura/Cursos", 'id': 6, 'checked':false},
    {"value":'fitness' ,"nome":"Fitness", 'id': 7, 'checked':false},
    {"value":'pets' ,"nome":"Pets", 'id': 8, 'checked':false},
    {"value":'gourmet' ,"nome":"Gourmet", 'id': 9, 'checked':false},
    {"value":'games' ,"nome":"Games", 'id': 10, 'checked':false},
    {"value":'moda' ,"nome":"Moda", 'id': 11, 'checked':false},
    {"value":'beleza' ,"nome":"Beleza", 'id': 12, 'checked':false},
    // {"value":'outros' ,"nome":"Outros", 'id': 13, 'checked':false}
];
public preferencias_atuais:any = [{}];
public preferencias_selecionadas:any = [{}];

  public user:User;
  enquete: any;
  pergunta: any;
  resposta: any;
  etapa: number = 0;
  porcentagens: any = [];
  ganhou_moedas: any = 0;

  propaganda:boolean = false;
  objetivos:boolean = false;
  
  user_id;
  user_name;

  moedas = 3.5;
  modulos_concluidos = 2;
  modulos_total = 5;

  //@Output() timer_video = new EventEmitter();

  public slideOpts = {
    slidesPerView: 1,
    speed: 300
  }
  
  moedas_ganhos;
  modal_ganhou:boolean = false;

  porcentagem = 0;
  count_navigate;

  modal;
  modal_banner_url;
  parceiros;
  topicos_compartilhamento;

  modulos = [
    { 'id': 1, 'campo' : 'modulo_enquete',      'nome': 'Modulo Enquete',      'moedas': 10, 'status':0 },
    { 'id': 2, 'campo' : 'modulo_sorteio',      'nome': 'Modulo Sorteio',      'moedas': 10, 'status':0 },
    { 'id': 3, 'campo' : 'modulo_leilao',       'nome': 'Modulo Leilao',       'moedas': 10, 'status':0 },
    { 'id': 4, 'campo' : 'modulo_ecoplay',      'nome': 'Modulo Ecomode',      'moedas': 10, 'status':0 },
    { 'id': 5, 'campo' : 'modulo_atendimento',  'nome': 'Modulo Atendimento',  'moedas': 10, 'status':0 },
    { 'id': 6, 'campo' : 'modulo_resgate',      'nome': 'Modulo Resgate',      'moedas': 10, 'status':0 }
  ]

  @ViewChild('modalAcao', { static: false }) private modalAcao: TemplateRef<any>;
 


  constructor( renderer: Renderer2, private route: ActivatedRoute, private router: Router, private modalService: NgbModal, private sanitizer: DomSanitizer, private http: HttpClient, private loader: NgxUiLoaderService) { 

  }


  ngOnInit() { 
 //localStorage.removeItem('userId');

  
 this.user_id = localStorage.getItem('userId');
 this.user_name = localStorage.getItem('userName');
 
 
 if(!this.user_id){
   this.router.navigate(['/login']);
 }


 this.obter_dados();
 this.calc_porcentagem();

  }

  update_preferencias(){
    let p = this.preferencias.filter( function(preferencia){ return preferencia.checked == true });
    this.preferencias_selecionadas = p ;
    console.log(this.preferencias_selecionadas);
    console.log(this.preferencias);
  }



  obter_dados(){

    this.http.get<any>(environment.API_URL.concat('pontos?id_participante='+this.user_id)).subscribe(data => {
      
      this.moedas = data.moedas;
      this.modulos_total = data.modulos_total;
      this.modulos_concluidos = data.modulos_concluidos;
      this.modulos = data.modulos;

      console.log(this.modulos);
    

      this.calc_porcentagem();
    });

  }

  assistir_video(){
    localStorage.setItem('timer', String(new Date().getTime() / 1000 + 5));
    this.propaganda = true;
  }

  abrir_objetivos(){
    this.objetivos = true;
  }


  calc_porcentagem(){
    this.porcentagem = (this.modulos_concluidos / this.modulos_total) * 100
  
  }

  reload_component(){
    setTimeout(() => {
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigate(['./'], {
        relativeTo: this.route
      })
    }, 1000);
  }


  obter_parceiros(){
    this.http.get<any>(environment.API_URL.concat('parceiros/obter_parceiros_home')).subscribe(data => {
      this.parceiros = data;
    });
  }

  obter_topicos_compartilhamento(){
    this.http.get<any>(environment.API_URL.concat('compartilhe/obter_topicos_home')).subscribe(data => {
      this.topicos_compartilhamento = data;
    });

  }

  abrir_modal_acao() {
    this.modalService.open(this.modalAcao, { windowClass: 'modal-acao', size: 'md' });
  }
}

