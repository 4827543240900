import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import * as $ from 'jquery';

@Component({
  selector: 'app-doar',
  templateUrl: './doar.component.html',
  styleUrls: ['./doar.component.scss'],
})
export class DoarComponent implements OnInit {

  @Input() isOpen;
  @Input() agradecimento;
  @Input() saldo;
  @Input() id_causa;
  
  ecoins = 5;
  @Output() doacao = new EventEmitter();
  @Output() fechar = new EventEmitter();
  @Output() mudar_causa = new EventEmitter();
  
  
  invalid:boolean = false;
  msg:string = null;

  constructor() { }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {

    if(changes.isOpen){
      this.isOpen = changes.isOpen.currentValue;
    }
    if(changes.agradecimento){
      this.agradecimento = changes.agradecimento.currentValue;
    }
    if(changes.saldo){
      this.saldo = changes.saldo.currentValue;
    }
    if(changes.msg){
      this.msg = changes.msg.currentValue;
    }

    setTimeout(function(){
        $('#modal_doar').css({ top : '65px' });
    }, 200);
  }

  close(){
    this.fechar.emit(true);
  }

  doar(){
   
    if(this.valida_valor()){
      this.doacao.emit({'doado' : this.ecoins, "id_causa" : this.id_causa});
    }

  }

  valida_valor(){
    this.invalid = true;

    console.log(this.saldo);
    console.log(this.ecoins);
  
    if( this.ecoins < 0.5 ){
      this.msg = 'Valor mínimo: 0.5 ecoins';
      return false;
    }
    else if( this.saldo < this.ecoins ){
      this.msg = 'Saldo insuficiente';
      return false;
    }

    this.msg = null;
    this.invalid = false;
    return true;
  }


}
