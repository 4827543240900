import { HttpClient } from '@angular/common/http';
import { Component, Injectable, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingController } from '@ionic/angular';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-preferencias',
  templateUrl: './preferencias.component.html',
  styleUrls: ['./preferencias.component.scss'],
}) @Injectable()

export class PreferenciasComponent implements OnInit {


  id_usuario;
  preferencias:Array<any> = [
    {'nome' : 'familia',        'img' : 'pref_fa.png', 'id': 1, 'selecionado': false},
    {'nome' : 'saude',          'img' : 'pref_sa.png', 'id': 2, 'selecionado': false},
    {'nome' : 'alimentacao',    'img' : 'pref_al.png', 'id': 3, 'selecionado': false},
    {'nome' : 'igualdade',      'img' : 'pref_ig.png', 'id': 4, 'selecionado': false},
    {'nome' : 'educacao',       'img' : 'pref_ed.png', 'id': 5, 'selecionado': false},
    {'nome' : 'meio ambiente',  'img' : 'pref_me.png', 'id': 6, 'selecionado': false},
  ]


  constructor(private router: Router, private loader: LoadingController, private http: HttpClient) { }

  ngOnInit() {
    
    //this.loading();    
    this.id_usuario = localStorage.getItem('userId');
  }


  async loading(){
    let loader = await this.loader.create({
      message: '',
      duration: 1500
    });
    await loader.present();
  }


  selecionar_preferencia(e){
    const id = e.target.id.replace('p','');
    const preferencia = this.preferencias.filter((p)=>{return p.id == id} ).shift();

    if(preferencia){
      
      let index = this.preferencias.indexOf(preferencia);
      const preferencias_selecionadas = this.preferencias.filter( (p)=> { return p.selecionado == true });
      
      if( preferencias_selecionadas.length >= 3 ){

        if(this.preferencias[index].selecionado == false){
          alert('Selecione até 3 preferencias');
          return;
        }
        else{
          this.preferencias[index].selecionado = false;
        }
      }
      else{
          this.preferencias[index].selecionado = !this.preferencias[index].selecionado;
      }
      
    }

  }



  confimar_preferencias(){

    const preferencias_selecionadas = this.preferencias.filter( (p)=> { return p.selecionado == true });

    if(!preferencias_selecionadas || preferencias_selecionadas.length < 3){
      alert('selecione 3 preferências');
      return;
    }

    let usuario = {
      preferencias: JSON.stringify(preferencias_selecionadas),
      primeiro_acesso: 'N'
    };

    this.http.post<any>(environment.API_URL.concat('participantes/update'),
    {id: this.id_usuario, user: usuario}).subscribe( data => {
      if(data.status == 1){
        alert('Preferencias atualizadas com sucesso');
        this.router.navigate(['/home']);
      }
      else{
        alert('Ocorreu um erro, tente novamente mais tarde');
      }
    })


  }


}
