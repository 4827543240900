import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CanActivate, Router } from '@angular/router';
import { tap, shareReplay } from 'rxjs/operators';
import { Cadastro } from '../classes/cadastro';
import { Login } from '../classes/login';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class AuthService {

  estruturaLogadaEmitter = new EventEmitter<boolean>();

  constructor(private http: HttpClient, private router: Router) { }

  private setSession(authResult) {
    if (authResult.status == 1) {
      const token = authResult.token;

      localStorage.setItem('token', token);
      localStorage.setItem('userId', authResult.u_id);
      localStorage.setItem('nome_usuario', authResult.nome);
    }

    return authResult;
  }

  login(user: Login) {
    let rota = '';
    if (localStorage.getItem('url_retorno')) {
      rota = localStorage.getItem('url_retorno');
    }
    //localStorage.clear();
    if (rota) {
      localStorage.setItem('url_retorno', rota);
    }

    return this.http.post<any>( environment.API_URL.concat('login'), user );
  }

  /* recuperar_senha(email: string) {
    return this.http.post<any>(
      environment.API_URL.concat('cadastro/recuperar_senha'),
      { email : email }
    );
  } */

  obter_dados_usuario() {
    let user_id = localStorage.getItem('userId');
    let user_token = localStorage.getItem('token');
    return this.http.post<any>(
      environment.API_URL.concat('participantes/obter_dados_usuario'),
      { user_id, user_token }
    );
  }

  enviar_cadastro(cadastro: Cadastro){
    return this.http.post<any>(environment.API_URL.concat('cadastro/cadastrar'), cadastro);
  }

  alterar_senha(user_id:number, senha_atual: string, nova_senha: string, nova_senha_c: string){

    return this.http.post<any>(
      environment.API_URL.concat('cadastro/alterar_senha'),
      {
        "senha_atual": senha_atual, 
        "nova_senha": nova_senha, 
        "nova_senha_c": nova_senha_c,
        "user_id": user_id 
      }
    );

  }

  atualizar_foto(arquivo){
    const formData = new FormData();
    formData.append('foto', arquivo);
    formData.append('user_id', localStorage.getItem('userId'));
    formData.append('user_token', localStorage.getItem('token'));

    return this.http.post<any>(environment.API_URL.concat('participantes/alterar_foto'), formData);

  }

  remover_foto(){
    const formData = new FormData();
    formData.append('user_id', localStorage.getItem('userId'));
    formData.append('user_token', localStorage.getItem('token'));

    return this.http.post<any>(environment.API_URL.concat('participantes/remover_foto'), formData);

  }

  atualizar_dados(dados){
    let user_id = localStorage.getItem('userId');
    let user_token = localStorage.getItem('token');
    return this.http.post<any>(environment.API_URL.concat('participantes/atualizar_dados'), {dados, user_id, user_token});
  }

  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    localStorage.removeItem('nome_usuario');

    localStorage.clear();

    this.estruturaLogadaEmitter.emit(false);
    this.router.navigate(['login']);
  }

  isLoggedIn() {
    let token = localStorage.getItem('token');
    return token ? true : false;
  }

  isLoggedOut() {
    return !this.isLoggedIn();
  }

}

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  constructor() { }

  mudaNomeEmitter = new EventEmitter<string>(true);
  ocultaNavbarEmitter = new EventEmitter<any>(true);
  SecaoMenuEmitter = new EventEmitter<any>(true);
}

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) { }

  canActivate() {
    if (this.authService.isLoggedIn()) {
      return true;
    }

    this.authService.logout();

    if (!localStorage.getItem('url_retorno') && document.location.hash != '#/home') {
      let redirect = document.location.hash.replace('#/', '');
      localStorage.setItem('url_retorno', redirect)
    }

    this.router.navigate(['home']);
    return false;

  }
}
