import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Renderer2, ViewChild, EventEmitter, Output, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ignoreElements } from 'rxjs/operators';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { environment } from 'src/environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-descontos',
  templateUrl: './descontos.component.html',
  styleUrls: ['./descontos.component.scss'],
})

export class DescontosComponent implements OnInit {

  propaganda:boolean = false;
  objetivos:boolean = false;
  acesso = true;

  user_id;
  user_name;

  moedas;
  modulos_concluidos;
  modulos_total;

  public slideOpts = {
    slidesPerView: 1,
    speed: 300
  }

  moedas_ganhos;
  modal_ganhou:boolean = false;

  porcentagem = 0;
  count_navigate;

  beneficios;
  modal;
  modal_banner_url;
  parceiros;
  topicos_compartilhamento;

  media_moedas;

  modulos = [
    { 'id': 1, 'campo' : 'modulo_enquete',      'nome': 'Modulo Enquete',      'moedas': 10, 'status':0 },
    { 'id': 2, 'campo' : 'modulo_sorteio',      'nome': 'Modulo Sorteio',      'moedas': 10, 'status':0 },
    { 'id': 3, 'campo' : 'modulo_leilao',       'nome': 'Modulo Leilao',       'moedas': 10, 'status':0 },
    { 'id': 4, 'campo' : 'modulo_ecoplay',      'nome': 'Modulo Ecomode',      'moedas': 10, 'status':0 },
    { 'id': 5, 'campo' : 'modulo_atendimento',  'nome': 'Modulo Atendimento',  'moedas': 10, 'status':0 },
    { 'id': 6, 'campo' : 'modulo_resgate',      'nome': 'Modulo Resgate',      'moedas': 10, 'status':0 }
  ]

  enquete2;

  @ViewChild('modalAcao', { static: false }) private modalAcao: TemplateRef<any>;

  constructor( renderer: Renderer2, private route: ActivatedRoute, private router: Router, private modalService: NgbModal, private sanitizer: DomSanitizer, private http: HttpClient, private loader: NgxUiLoaderService) { 

  }

  ngOnInit() {
  
    this.user_id = localStorage.getItem('userId');
    this.user_name = localStorage.getItem('userName');
    
    
    if(!this.user_id){
      this.router.navigate(['/login']);
    }

    this.obter_dados();
    //this.counter_bonus();
    this.obter_beneficios();
    this.obter_parceiros();
    this.calc_porcentagem();
    
  }

  obter_dados(){

    this.http.get<any>(environment.API_URL.concat('pontos?id_participante='+this.user_id)).subscribe(data => {
      
      this.moedas = data.moedas;
      this.modulos_total = data.modulos_total;
      this.modulos_concluidos = data.modulos_concluidos;
      this.modulos = data.modulos;

      console.log(this.modulos);

      this.calc_porcentagem();
    });

  }


  obter_beneficios() {
    this.http.get(environment.API_URL.concat('beneficios/obter_beneficios')).subscribe((data: any) => {
        this.beneficios = data;
    })
  }

  abrir_objetivos(){
    this.objetivos = true;
  }

  registrar_acesso(beneficio) {
        console.log(beneficio);

        // (<any>window).dataLayer.push({
        //     'event': 'clubeMDOEvents',
        //     'category': beneficio.nome,
        //     'action': 'clique'
        // });

        this.http.post(environment.API_URL.concat('beneficios/registrar_acesso'),
            { 'beneficio': beneficio }).subscribe((data: any) => {
                console.log('registrado');
            });
    }

  calc_porcentagem(){
    this.porcentagem = (this.modulos_concluidos / this.modulos_total) * 100
    this.media_moedas = 100 / this.modulos_total; 
  }

  obter_modal() {
    this.http.post<any>(environment.API_URL.concat('acoes/modal'), {
        id_usuario: this.user_id
    }).subscribe((data: any) => {
        if (data.status == 1) {
            this.modal = data.modal;
            this.modal_banner_url = this.sanitizer.bypassSecurityTrustResourceUrl(this.modal.banner);
            setTimeout(() => {
                this.abrir_modal_acao()
            }, 2000);

        }

    })
  }

  obter_parceiros(){
    this.http.get<any>(environment.API_URL.concat('parceiros/obter_parceiros_home')).subscribe(data => {
      this.parceiros = data;
    });
  }

  obter_topicos_compartilhamento(){
    this.http.get<any>(environment.API_URL.concat('compartilhe/obter_topicos_home')).subscribe(data => {
      this.topicos_compartilhamento = data;
    });

  }

  abrir_modal_acao() {
    this.modalService.open(this.modalAcao, { windowClass: 'modal-acao', size: 'md' });
  }
}


