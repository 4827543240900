import { Component, Injectable, OnInit, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import * as $ from 'jquery';

@Component({
  selector: 'app-placar',
  templateUrl: './placar.component.html',
  styleUrls: ['./placar.component.scss'],
}) @Injectable()

export class PlacarComponent implements OnInit {

  constructor(private modalController: ModalController, private route: ActivatedRoute, private router:Router) { }
 
  @Input() isOpen = true;
  @Output() ganhou_moedas = new EventEmitter();
  
  @Input() moedas_saldo;
  moedas;
  @Input() media_moedas;
  moedas_anima;

  ngOnInit() {
    setTimeout(()=>{
      this.moedas = this.moedas_saldo;
    }, 2000)
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.

    if( changes.isOpen ){
      this.isOpen = changes.isOpen.currentValue;
      this.receber_premio();

    }

  }

  receber_premio(){

    let novo_saldo = this.moedas + this.media_moedas;
    this.moedas_anima = this.media_moedas;
    
    let earning = setInterval(()=>{

      if(this.moedas_anima > 0.1){
        this.moedas += 0.29;
        this.moedas_anima -= 0.29;
      }
      else{
        this.moedas_anima = 0;
        this.moedas = novo_saldo;
        $(".box_ganho").fadeOut(300);
        $("#novo_saldo").fadeIn(2000);
        clearInterval(earning);
      }
    }, 20);


  }

  


}
