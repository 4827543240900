import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-creditos-carbono',
  templateUrl: './creditos-carbono.component.html',
  styleUrls: ['./creditos-carbono.component.scss'],
})
export class CreditosCarbonoComponent implements OnInit {

  user_id;
  user_name;

  ecoins;
  causas_apoiadas;
  ecoins_doados;

  constructor(private router:Router, private http:HttpClient) { }

  ngOnInit() {
    
    this.user_id = localStorage.getItem('userId');
    this.user_name = localStorage.getItem('userName');
    
    
    if(!this.user_id){
      this.router.navigate(['/login']);
    }
    this.obter_dados();
    this.counter_bonus();    
  }

  obter_dados(){
    
    this.http.get<any>(environment.API_URL.concat('pontos?id_participante='+this.user_id)).subscribe(data => {
      this.ecoins = data.ecoins;
      this.ecoins_doados = data.ecoins_doados;
      this.causas_apoiadas = data.causas_apoiadas;
    });
    
  }

  counter_bonus(){
    let count_navigate = parseInt(localStorage.getItem('count_navigate')) + 1;
    localStorage.setItem('count_navigate', String(count_navigate));
  }


}
