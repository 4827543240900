import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-suporte',
  templateUrl: './suporte.component.html',
  styleUrls: ['./suporte.component.scss'],
})
export class SuporteComponent implements OnInit {

  enquete: any;
  pergunta: any;
  resposta: any;
  etapa: number = 0;
  porcentagens: any = [];
  ganhou_moedas: any = 0;

  propaganda:boolean = false;
  objetivos:boolean = false;
  
  user_id;
  user_name;

  moedas;
  modulos_concluidos;
  modulos_total;

  //@Output() timer_video = new EventEmitter();

  public slideOpts = {
    slidesPerView: 1,
    speed: 300
  }

  moedas_ganhos;
  modal_ganhou:boolean = false;

  porcentagem = 0;
  count_navigate;

  concluido;
  modal;
  modal_banner_url;
  parceiros;
  topicos_compartilhamento;

  media_moedas;

  modulos = [
    { 'id': 1, 'campo' : 'modulo_enquete',      'nome': 'Modulo Enquete',      'moedas': 10, 'status':0 },
    { 'id': 2, 'campo' : 'modulo_sorteio',      'nome': 'Modulo Sorteio',      'moedas': 10, 'status':0 },
    { 'id': 3, 'campo' : 'modulo_leilao',       'nome': 'Modulo Leilao',       'moedas': 10, 'status':0 },
    { 'id': 4, 'campo' : 'modulo_ecoplay',      'nome': 'Modulo Ecomode',      'moedas': 10, 'status':0 },
    { 'id': 5, 'campo' : 'modulo_atendimento',  'nome': 'Modulo Atendimento',  'moedas': 10, 'status':0 },
    { 'id': 6, 'campo' : 'modulo_resgate',      'nome': 'Modulo Resgate',      'moedas': 10, 'status':0 }
  ]

  enquete2;


  constructor( private route: ActivatedRoute, private router: Router, private modalService: NgbModal, private http: HttpClient, private loader: NgxUiLoaderService) { 

  }

  ngOnInit() {
    //localStorage.removeItem('userId');

  
    this.user_id = localStorage.getItem('userId');
    this.user_name = localStorage.getItem('userName');
    
    
    if(!this.user_id){
      this.router.navigate(['/login']);
    }


    this.obter_dados();
    //this.counter_bonus();
    this.calc_porcentagem();
    
  }

  obter_dados(){

    this.http.get<any>(environment.API_URL.concat('pontos?id_participante='+this.user_id)).subscribe(data => {
      
      this.moedas = data.moedas;
      this.modulos_total = data.modulos_total;
      this.modulos_concluidos = data.modulos_concluidos;
      this.modulos = data.modulos;

      if( data.modulos[5].status == 1 ){
        this.concluido = 1
      }
    
      console.log(this.modulos);

      this.calc_porcentagem();
    });

  }

  assistir_video(){
    localStorage.setItem('timer', String(new Date().getTime() / 1000 + 5));
    this.propaganda = true;
  }

  abrir_objetivos(){
    this.objetivos = true;
  }

  assistiu_video(event){
    
    this.modal_ganhou = true;

    let descricao = "Assistiu Vídeo";


    this.http.post<any>(environment.API_URL.concat('pontos/creditar_moedas'), {
      id_participante: this.user_id, 
      moedas: event.ganhou, 
      descricao: descricao,
      id_relacionamento: 1
    }).subscribe( data => {
      if(data.status == 1){

        this.propaganda = false;
        this.animacao_saldo();
        this.calc_porcentagem();
        this.obter_dados();
      }
      else{
        console.log("Erro");
      }
    });
    
  }


  responder_enquete(id_relacionamento) {
    
    this.loader.start();
    this.http.post<any>(environment.API_URL.concat('pontos/creditar_moedas'), {
      id_participante: this.user_id,
      id_relacionamento: id_relacionamento,
      descricao: this.enquete,
      moedas: this.media_moedas
    }).subscribe(data => {

      if(data.status == 1){

        const audioWin = new Audio('assets/audio/audioCoin.mpeg');
        audioWin.play();

        this.modal_ganhou = true;
        this.animacao_saldo()
        this.obter_dados();
      }
      else{
        alert(data.message);
       
      }
      this.loader.stop();
    });
  }

  animacao_saldo(){
    let novo_saldo = this.moedas + this.moedas_ganhos;
    let animacao = setInterval(()=>{ 

      if( this.moedas < novo_saldo ){
        this.moedas += 0.1;
        this.moedas_ganhos -= 0.1;
      }
      else{
        this.moedas = novo_saldo;
        this.moedas_ganhos = 0;
        clearInterval(animacao);
        this.calc_porcentagem();

        setTimeout(()=>{
          this.modal_ganhou = false;
        }, 2000)
      }

    }, 50);

  }

  counter_bonus(){
    let count_navigate;

    if( parseInt(localStorage.getItem('count_navigate')) > 0){
      count_navigate = parseInt(localStorage.getItem('count_navigate')) + 1;
    }
    else{
      count_navigate = 1;
    }

    localStorage.setItem('count_navigate', String(count_navigate));
    this.count_navigate = count_navigate;

    if(this.count_navigate >= 3){
      setTimeout(() => {

        this.http.post<any>(environment.API_URL.concat('pontos/verificar_bonus'),
        {id_participante : this.user_id}).subscribe( data => {
          if(data.status == 1){

            this.modal_ganhou = true;
            this.moedas_ganhos = data.moedas_ganhos;

            this.animacao_saldo();
            this.calc_porcentagem();
            localStorage.setItem('count_navigate', '0');

          }
        });
      }, 3000);
    }

  }


  calc_porcentagem(){
    this.porcentagem = (this.modulos_concluidos / this.modulos_total) * 100
    this.media_moedas = 100 / this.modulos_total; 
    this.moedas_ganhos = this.media_moedas;
  }

  reload_component(){
    setTimeout(() => {
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigate(['./'], {
        relativeTo: this.route
      })
    }, 1000);
  }


}
