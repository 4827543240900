import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { environment } from 'src/environments/environment';
import * as $ from 'jquery';
@Component({
  selector: 'app-primeiro-acesso',
  templateUrl: './primeiro-acesso.component.html',
  styleUrls: ['./primeiro-acesso.component.scss'],
})

export class PrimeiroAcessoComponent implements OnInit {


  user_name;

  constructor(private loader: NgxUiLoaderService, private http:HttpClient, private router:Router) { }

  ngOnInit() {
    this.validar();

    setInterval(function(){
      let slide_ativo = $(".swiper-slide-active").attr('id');
      console.log(slide_ativo);

      switch(slide_ativo){
        case"s1":
          $(".swiper-pagination-bullet-active").css('background-color', "#6a1267");
        break;
        case"s2":
          $(".swiper-pagination-bullet-active").css('background-color', "#f507d5");
        break;
        case"s3":
          $(".swiper-pagination-bullet-active").css('background-color', "#e66210");
        break;
      }
    }, 500);
  }

  validar(){

    const id_usuario = localStorage.getItem('userId');
    this.user_name = localStorage.getItem('userName');

    if(!id_usuario)
      this.router.navigate(['/login']);

    this.http.get<any>(environment.API_URL.concat('participantes/obter_dados/'+id_usuario)).subscribe( data => {
      if(data.status == 1){
        if(data.usuario.primeiro_acesso == 'N'){
          this.router.navigate(['/home']);
        }
      }
      else{
        return true;
      }
    });

  }
}
