import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { CheckboxCustomEvent } from '@ionic/angular';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-final',
  templateUrl: './final.component.html',
  styleUrls: ['./final.component.scss'],
})
export class FinalComponent implements OnInit {

  constructor( private router : Router, private http:HttpClient) { }

  canDismiss = false;
  presentingElement = null;
  agradecimento;
  @Input() isOpen = true;
  @Input() concluido;
  @Output() fechar = new EventEmitter();

  user_id;
  user_name;

  ngOnInit() {

    this.user_id = localStorage.getItem('userId');
    this.user_name = localStorage.getItem('userName');

  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    console.log(changes);

    if(changes.isOpen.currentValue == false){
      this.isOpen = false;
    }

  }

  onTermsChanged(event: Event) {
    const ev = event as CheckboxCustomEvent;
    this.canDismiss = ev.detail.checked;
  }

  close() {
    this.fechar.emit({ fechar: true });
  }

  close_routing(route){
    this.fechar.emit({fechar : true});

    setTimeout(()=>{
      this.router.navigate([route]);
    },500);
  }

  enviar_avaliacao(avaliacao){

    if(window.confirm("Confirmar avaliação")){

      this.http.post<any>(environment.API_URL.concat('participantes/avaliar'), 
      { id_participante: this.user_id, avaliacao : avaliacao } ).subscribe( data => {
        
        if(data.status == 1){
          localStorage.setItem('agradecimento', 'true');
          this.agradecimento = true;

          this.fechar.emit({concluido : 'sim'});
        }

      });

    }

  }

}
