import { Component, Injectable, Output, EventEmitter, OnInit, HostListener, ViewChild, TemplateRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Login } from 'src/app/classes/login';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { CookieService } from 'ngx-cookie-service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import * as $ from 'jquery';

@Component({
    selector: 'login-component',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
}) @Injectable({
    providedIn: 'root'
})


export class LoginComponent implements OnInit {

    public eye = faEye;

    @ViewChild('modalInstalarIos', { static: false }) private modalInstalarIos: TemplateRef<any>;
    modalReference: any;
    
   
    constructor(private http: HttpClient, private route: ActivatedRoute, private router: Router, private auth: AuthService, private loader: NgxUiLoaderService, private modalService: NgbModal, private cookieService: CookieService) {
        this.user = new Login;
    }

    ngOnInit() {

        this.loader.start();

        const token = this.route.snapshot.paramMap.get('token');

        console.log(token);
        
        if (token) {
            this.realizar_login(token);
        }

        if (localStorage.getItem('userId')){
            this.router.navigate(['/home']);
        }

        this.loader.stop();
    }


    public user: Login;
    public loggedIn: boolean;

    deferredPrompt: any;
    exibir_instalar = false;

    @HostListener('window:beforeinstallprompt', ['$event'])
    onbeforeinstallprompt(e) {
        console.log(e);
        // Prevent Chrome 67 and earlier from automatically showing the prompt
        e.preventDefault();
        // Stash the event so it can be triggered later.
        this.deferredPrompt = e;
        this.exibir_instalar = true;
    }

    realizar_login(token){
        this.http.get(environment.API_URL.concat( ('login/index/').concat(token) )).subscribe((data: any) => {

            if (data.status == 1) {

                let nome = data.user.nome;

                if(nome){
                    localStorage.setItem('isLogged', 'true');
                    localStorage.setItem('userName', nome);
                    localStorage.setItem('userId', data.user.id);
                    localStorage.setItem('ultimo_acesso', data.user.ultimo_acesso);
                    localStorage.setItem('acesso', data.user.acesso_beneficios);
            
                    if(data.user.primeiro_acesso = "S"){
                        this.router.navigate(['primeiro-acesso']);
                    }
                    else{
                        this.router.navigate(['home']);
                    }
                }

            }
            else {
                console.log(data);
            }
        });
    }


}